import Swiper from "swiper"
import Typed from "typed.js"

if (document.querySelector(".front-reviews__slider")) {
    new Swiper(".front-reviews__slider", {
        autoplay: false,
        autoHeight: true,
        calculateHeight: true,
        slidesPerView: 1,
        initialSlide: Math.floor(Math.random() * 3),
        navigation: {
            nextEl: ".front-reviews__navigation_button_next",
            prevEl: ".front-reviews__navigation_button_prev"
        },
        pagination: {
            el: ".front-reviews__pagination",
            type: "bullets",
            clickable: true
        },
        loop: true
    })
}

window.toggleSolutions = function () {
    document.querySelector(".our-solution").classList.toggle("show")
}
const typed = document.querySelector("#typed_text")
const strings = JSON.parse(typed.dataset.strings)
let options = {
    strings: strings,
    typeSpeed: 75,
    backSpeed: 50,
    backDelay: 800,
    startDelay: 500,
    loop: true,
    showCursor: false,
    cursorChar: "|",
    attr: null
}
if (typed) {
    new Typed("#typed_text", options)
}
let canChanged = true
let mainImageVariants = [
    {
        xmobi: "/build/img/home/mainscreen_1_320.jpg",
        mobi: "/build/img/home/mainscreen_1_480.jpg",
        tablet: "/build/img/home/mainscreen_1_640.jpg",
        decktop: "/build/img/home/mainscreen_1_960.jpg",
        main: "/build/img/home/mainscreen_1.jpg"
    },
    {
        xmobi: "/build/img/home/mainscreen_2_320.jpg",
        mobi: "/build/img/home/mainscreen_2_480.jpg",
        tablet: "/build/img/home/mainscreen_2_640.jpg",
        decktop: "/build/img/home/mainscreen_2_960.jpg",
        main: "/build/img/home/mainscreen_2.jpg"
    },
    {
        xmobi: "/build/img/home/mainscreen_3_320.jpg",
        mobi: "/build/img/home/mainscreen_3_480.jpg",
        tablet: "/build/img/home/mainscreen_3_640.jpg",
        decktop: "/build/img/home/mainscreen_3_960.jpg",
        main: "/build/img/home/mainscreen_3.jpg"
    },
    {
        xmobi: "/build/img/home/mainscreen_4_320.jpg",
        mobi: "/build/img/home/mainscreen_4_480.jpg",
        tablet: "/build/img/home/mainscreen_4_640.jpg",
        decktop: "/build/img/home/mainscreen_4_960.jpg",
        main: "/build/img/home/mainscreen_4.jpg"
    },
    {
        xmobi: "/build/img/home/mainscreen_5_320.jpg",
        mobi: "/build/img/home/mainscreen_5_480.jpg",
        tablet: "/build/img/home/mainscreen_5_640.jpg",
        decktop: "/build/img/home/mainscreen_5_960.jpg",
        main: "/build/img/home/mainscreen_5.jpg"
    },
    {
        xmobi: "/build/img/home/mainscreen_320.jpg",
        mobi: "/build/img/home/mainscreen_480.jpg",
        tablet: "/build/img/home/mainscreen_640.jpg",
        decktop: "/build/img/home/mainscreen_960.jpg",
        main: "/build/img/home/mainscreen.jpg"
    }
]
document.addEventListener("scroll", () => {
    const trigger = document.querySelector("#changeMainImg")

    if (trigger) {
        let { top } = trigger.getBoundingClientRect()
        if (top <= 0) {
            if (canChanged) {
                if (Math.random() < 0.6) {
                    changeMainImage()
                }
                canChanged = false
            }
        } else {
            canChanged = true
        }
    }
})
function changeMainImage() {
    let randomImage = Math.floor(Math.random() * 5)
    let activeImage = mainImageVariants[randomImage]

    document.querySelector("#mainXMobiImage").setAttribute("srcset", activeImage.xmobi)
    document.querySelector("#mainMobiImage").setAttribute("srcset", activeImage.mobi)
    document.querySelector("#mainTabletImage").setAttribute("srcset", activeImage.tablet)
    document.querySelector("#mainDesctopImage").setAttribute("srcset", activeImage.decktop)
    document.querySelector("#mainImage").setAttribute("src", activeImage.main)

    mainImageVariants.push(mainImageVariants[randomImage])
    mainImageVariants.splice(randomImage, 1)
}
